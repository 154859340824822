@import "./variables";
.menu {
  display: flex;
  flex-direction: column;
  gap: 70px;

  padding-bottom: 100px;

  h1 {
    font-size: 20px;
    color: #555;
  }

  .post {
    display: flex;
    flex-direction: column;
    gap: 7px;

    img {
      width: 100%;
      height: 200px;
      object-fit: cover;
    }

    h2 {
      font-family: "Quicksand", sans-serif;
    }
    button {
      width: max-content;
      padding: 10px 3vw;
      border: none;
      cursor: pointer;
      background-color: azure;
      border: 1px solid $TiffanyBlue;

      color: $TiffanyBlue;

      a {
        text-decoration: none;
        color: $BrussianBlue;
        font-size: medium;
      }

      &:hover {
        border: 1px solid azure;
      }
    }
  }
}
