@import "./variables";

.mark {
  display: flex;
  margin: 50px;
  margin-left: 5vw;
  margin-right: 5vw;
  gap: 0;
  .textInput {
    width: 40%;
    height: 70vh;
    font-size: 20px;
    background-color: wheat;
    outline: none;
    border: 4px solid;
    border-image: linear-gradient(45deg, #f3ec78, #af4261) 1;
    padding: 20px;
    border-radius: 10px;
    margin: 0;
    margin-right: 5px;
    background-color: rgba($color: grey, $alpha: 0.5);
    color: wheat;
  }
  .markdown-container {
    color: wheat;
    margin: 0;
    margin-left: 5px;
    width: 60%;
    height: 70vh;
    padding: 20px;
    font-size: 18px;
    background-color: rgba($color: grey, $alpha: 0.5);
    outline: none;
    overflow-wrap: break-word;
    word-break: break-word;
    overflow-y: auto;
    border: 4px solid;
    border-image: linear-gradient(45deg, #1e90ff, #32cd32) 1;
    border-radius: 10px;
  }
  .markdown-container h1,
  .markdown-container h2,
  .markdown-container h3,
  .markdown-container h4,
  .markdown-container h5,
  .markdown-container h6 {
    font-weight: bold;
    line-height: 1.2;
    margin: 1em 0 0.5em;
  }

  .markdown-container h1 {
    font-size: 2.5em;
    border-bottom: 2px solid #ddd;
    padding-bottom: 0.3em;
  }

  .markdown-container h2 {
    font-size: 2em;
    border-bottom: 1px solid #ddd;
    padding-bottom: 0.3em;
  }

  .markdown-container h3 {
    font-size: 1.75em;
  }

  .markdown-container h4 {
    font-size: 1.5em;
  }

  .markdown-container h5 {
    font-size: 1.25em;
  }

  .markdown-container h6 {
    font-size: 1em;
  }

  // Paragraphs
  .markdown-container p {
    margin: 1em 0;
  }

  // Links
  .markdown-container a {
    color: #3498db;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  // Lists
  .markdown-container ul,
  .markdown-container ol {
    padding-left: 20px;
    margin: 1em 0;
  }

  .markdown-container ul {
    list-style-type: disc;
  }

  .markdown-container ol {
    list-style-type: decimal;
  }

  // Blockquotes
  .markdown-container blockquote {
    margin: 1.5em 0;
    padding: 0.5em 1em;
    background-color: #f9f9f9;
    border-left: 4px solid #ccc;
    font-style: italic;
  }

  // Code
  .markdown-container pre {
    background-color: #f6f8fa;
    padding: 1em;
    border-radius: 5px;
    overflow-x: auto;
  }

  .markdown-container code {
    background-color: #f6f8fa;
    padding: 0.2em 0.4em;
    border-radius: 3px;
    font-family: "Fira Code", monospace;
  }

  // Tables
  .markdown-container table {
    width: 100%;
    border-collapse: collapse;
    margin: 1em 0;
  }

  .markdown-container th,
  .markdown-container td {
    padding: 0.75em 1em;
    border: 1px solid #ddd;
  }

  .markdown-container th {
    background-color: #f5f5f5;
    font-weight: bold;
    text-align: left;
  }

  .markdown-container tr:nth-child(even) {
    background-color: #f9f9f9;
  }

  // Horizontal rules
  .markdown-container hr {
    border: 0;
    border-top: 1px solid #eee;
    margin: 2em 0;
  }
}
