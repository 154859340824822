@import "./_variables.scss";

.homepage {
  .but {
    width: max-content; // 按钮宽度自适应
    padding: 10px 16px; // 按钮内边距
    border: none; // 无边框
    cursor: pointer; // 鼠标指针
    background-color: azure; // 背景色
    border: 1px solid $TiffanyBlue; // 边框色
    color: $TiffanyBlue; // 字体颜色

    a {
      text-decoration: none; // 无下划线
      color: white; // 链接颜色
    }

    &:hover {
      background-color: $TiffanyBlue; // 悬停时背景色变化
      color: white; // 悬停时字体颜色变化
    }
  }
  .posts {
    margin-top: 0;
    margin-bottom: 100px;
    display: flex;
    row-gap: 50px;
    flex-flow: wrap;

    margin-left: 10vw;
    margin-right: 10vw;
    .but {
      width: max-content; // 按钮宽度自适应
      padding: 10px 16px; // 按钮内边距
      border: none; // 无边框
      cursor: pointer; // 鼠标指针
      background-color: azure; // 背景色
      border: 1px solid $TiffanyBlue; // 边框色
      color: $TiffanyBlue; // 字体颜色

      a {
        text-decoration: none; // 无下划线
        color: white; // 链接颜色
      }

      &:hover {
        background-color: $TiffanyBlue; // 悬停时背景色变化
        color: white; // 悬停时字体颜色变化
      }
    }
  }
}
