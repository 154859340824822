@import "./variables.scss";
.auther {
  display: flex;
  align-items: center;

  flex-direction: column;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-image: url("https://res.cloudinary.com/dzzrskazv/image/upload/v1726562282/do2ghj67reijlap6oj5t.jpg");

  h1 {
    font-size: 100px;
    font-family: "Times New Roman", Times, serif;

    color: $BurgundyRed;
  }

  form {
    display: flex;
    flex-direction: column;
    padding: 50px;

    background-color: antiquewhite;
    gap: 15px;
    width: 25vw;
    height: auto;
    border-radius: 5px;

    input {
      padding: 10px;
      border: none;
      border-bottom: 1px solid grey;
      background-color: antiquewhite;
    }

    button {
      padding: 10px;
      border: none;
      background-color: $BurgundyRed;
      color: antiquewhite;
      font-size: 20px;
      cursor: pointer;
    }

    p {
      color: $AccentColor;
      text-align: center;
      margin-bottom: 5px;
    }

    span {
      text-align: center;
    }
  }
}
