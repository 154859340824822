.markdown-container {
  max-width: 900px; /* 更窄的宽度，类似于印刷品 */
  margin: 0 auto;
  padding: 20px;
  font-family: "Georgia", serif; /* 使用 serif 字体增强印刷感 */
  font-size: 16px;
  line-height: 1.8; /* 更宽松的行距 */
  color: #222; /* 更深的颜色模拟印刷品墨色 */
  background-color: #fdfcf8; /* 仿纸质的背景色 */
  word-break: break-word;
  overflow-wrap: break-word;
}

// Headings
.markdown-container h1,
.markdown-container h2,
.markdown-container h3,
.markdown-container h4,
.markdown-container h5,
.markdown-container h6 {
  font-family: "Times New Roman", serif; /* 标题采用经典印刷风格字体 */
  font-weight: bold;
  line-height: 1.3;
  margin: 1.2em 0 0.8em;
}

.markdown-container h1 {
  font-size: 2.4em;
  text-transform: uppercase;
  border-bottom: 3px double #555; /* 双线分隔 */
  padding-bottom: 0.4em;
  margin-bottom: 0.8em;
}

.markdown-container h2 {
  font-size: 2em;
  border-bottom: 2px solid #aaa;
  padding-bottom: 0.3em;
}

.markdown-container h3 {
  font-size: 1.6em;
  text-transform: uppercase; /* 标题大写 */
  color: #444;
}

.markdown-container h4 {
  font-size: 1.4em;
}

.markdown-container h5 {
  font-size: 1.2em;
}

.markdown-container h6 {
  font-size: 1em;
  font-style: italic; /* h6使用斜体模拟副标题 */
}

// Paragraphs
.markdown-container p {
  margin: 1.2em 0;
  text-indent: 2em; /* 段首缩进模拟书籍排版 */
}

// Links
.markdown-container a {
  color: #0056b3;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}

// Lists
.markdown-container ul,
.markdown-container ol {
  padding-left: 1.5em;
  margin: 1em 0;
}

.markdown-container ul {
  list-style-type: square; /* 方形列表符号 */
}

.markdown-container ol {
  list-style-type: decimal;
}

// Blockquotes
.markdown-container blockquote {
  margin: 1.5em 0;
  padding: 0.8em 1.2em;
  background-color: #f4f4f4;
  border-left: 4px solid #999;
  font-family: "Georgia", serif;
  font-style: italic;
  color: #555;
}

// Code
.markdown-container pre {
  background-color: #fdfcf8;
  padding: 1em;
  border: 1px solid #ddd;
  border-radius: 0;
  overflow-x: auto;
  font-family: "Courier New", monospace; /* 经典等宽字体 */
  line-height: 1.4;
}

.markdown-container code {
  background-color: #f9f9f9;
  padding: 0.1em 0.2em;
  border-radius: 3px;
  font-family: "Courier New", monospace;
  color: #c7254e;
  font-size: 0.95em;
}

// Tables
.markdown-container table {
  width: 100%;
  border-collapse: collapse;
  margin: 1.5em 0;
}

.markdown-container th,
.markdown-container td {
  padding: 0.8em 1em;
  border: 1px solid #bbb;
}

.markdown-container th {
  background-color: #f5f5f5;
  font-weight: bold;
  text-align: left;
  font-family: "Georgia", serif;
  text-transform: uppercase; /* 列标题大写 */
}

.markdown-container tr:nth-child(even) {
  background-color: #f8f8f8;
}

// Horizontal rules
.markdown-container hr {
  border: 0;
  border-top: 2px dashed #bbb; /* 虚线样式 */
  margin: 2em 0;
}
