@import "_variables.scss";

main {
  display: flex;
  flex-direction: column;
  background-color: #f7f7f7; // 使用接近白色的简约背景色

  .site-navigation {
    height: 70px;
    width: 100%;
    background: #f7f7f7;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $text-color;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 0 20px;

    a.tit {
      font-size: 35px;
      display: flex;
      align-items: center;
      padding-left: 20px;
      font-weight: 500;
      font-family: "Playfair Display", serif;
      font-style: italic;
      text-decoration: none;
      color: inherit;

      .red {
        color: $BurgundyRed;
      }
    }

    .links {
      display: flex;
      align-items: center;
      font-size: 18px;
      margin-right: 40px;

      .link {
        margin: 0 15px;
        text-decoration: none;
        font-weight: 500;
        font-family: "Quicksand", sans-serif;

        &:hover {
          text-decoration: underline;
          color: $BurgundyRed;
        }
      }
    }
  }

  navbar {
    height: auto;
    width: 100%;
    background-color: #f0f0f0; // 次级导航栏的浅色背景
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    box-shadow: 0 1px 1px 1px grey;

    .links {
      margin-left: 20px;
      display: flex;
      align-items: center;
      font-size: 18px;

      .link {
        margin: 0 10px;
        text-decoration: none;
        font-weight: 500;
        font-family: "Quicksand", sans-serif;

        &:hover {
          text-decoration: underline;
          color: $BurgundyRed;
        }
      }
    }

    .writes {
      display: flex;
      align-items: center;
      margin-right: 20px;

      .write {
        margin: 10px;
        width: 120px;
        color: whitesmoke;
        font-weight: 800;
        border-radius: 4px;
        text-decoration: none;
        text-align: center;
        background-color: $BrussianBlue;
        padding: 8px 16px;

        &:hover {
          background-color: $BurgundyRed;
        }

        &:active {
          background-color: $TiffanyBlue;
        }
      }
    }
  }
}

// 响应式设计
@media (max-width: 768px) {
  .site-navigation {
    flex-direction: column;
    height: auto;
    padding: 10px;

    a.tit {
      font-size: 28px;
      padding-left: 0;
    }

    .links {
      margin-right: 0;
      justify-content: center;
      font-size: 16px;
    }
  }

  navbar {
    flex-direction: column;
    padding: 10px;

    .links {
      justify-content: center;
      font-size: 16px;
    }

    .writes {
      margin: 10px 0;
      justify-content: center;
    }
  }
}
