@import "./_variables.scss";

.foo {
  background-color: grey;
  display: flex;

  height: 100px;
  align-items: center;
  justify-content: center;
  .at {
    font-size: small;
    align-items: center;
    font-family: "Times New Roman", Times, serif;
    color: azure;
  }
}
