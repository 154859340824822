@import "./variables";
.single {
  display: flex;
  margin: 70px;
  margin-left: 5vw;
  margin-right: 5vw;
  margin-bottom: 0;
  margin-top: 0;
  padding-top: 70px;
  gap: 7vw;

  .content {
    flex: 5;
    align-items: center;
    img {
      height: 300px;
      width: 100%;
      object-fit: cover;
      margin-bottom: 20px;
    }
    .information {
      display: flex;
      justify-content: space-between;
      .user {
        display: flex;
        height: auto;
        gap: 10px;
        font-size: 14px;
        img {
          width: 60px;
          height: 60px;
          border-radius: 50%;
          object-fit: cover;
          align-self: center;
        }
        .info {
          margin: 20px;
          font-size: 18px;
          font-family: "Quicksand", sans-serif;
          .name {
            font-weight: 600;
            font-size: 20px;
          }
        }
      }
      .edit {
        font-size: 20px;
        font-family: "Quicksand", sans-serif;
        color: white;
        font-weight: 800;
        display: flex;
        align-items: center;
        margin-right: 30px;
        gap: 20px;
        .editing {
          height: auto;
          font-family: "Quicksand", sans-serif;
          font-weight: 800;
          padding: 30px;
          font-size: 20px;
          width: 100px;
          padding: 5px;
          border-radius: 2px;
          text-align: center;
          align-self: center;
          text-decoration: none;
          background-color: $BrussianBlue;
          color: white;
          &:active {
            color: white;
          }
          &:hover {
            background-color: $TiffanyBlue;
          }
        }
      }
      .delete {
        height: auto;
        width: 100px;
        padding: 5px;
        border-radius: 2px;
        text-align: center;
        align-self: center;
        text-decoration: none;
        background-color: $BurgundyRed;
        color: white;
        &:active {
          color: white;
        }
        &:hover {
          background-color: $TiffanyBlue;
        }
      }
    }
  }

  .menu {
    flex: 2;
  }
}
