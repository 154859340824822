@import "./variables";

.write1 {
  display: flex;
  gap: 20px;
  flex-direction: column;

  background-image: url("../../public/postphotos/cat.jpg");
  .menu {
    display: flex;
    margin: 80px;
    margin-bottom: 120px;
    gap: 10vw;
    align-items: flex-end;
    .item {
      display: flex;
      background-color: rgba($color: white, $alpha: 0.5);
      flex-direction: column;
      padding: 30px;
      width: 40vw;
      gap: 10px;
      height: auto;
      border: 2px solid #ddd;
      border-radius: 15px;
      padding: 20px;
      box-shadow: 0 4px 8;
      .buttons {
        display: flex;
        gap: 20px;

        button {
          width: max-content;
          padding: 10px 3vw;
          border: none;
          cursor: pointer;
          background-color: azure;
          border: 1px solid $TiffanyBlue;

          color: $TiffanyBlue;

          &:hover {
            border: 1px solid $BurgundyRed;
            background-color: $BurgundyRed;
          }

          &:active {
            background-color: $AccentColor;
          }
        }
      }
    }
  }
}
