header {
  .App {
    h1 {
      font-family: "Playfair Display", serif;
      font-size: 7vw;
      text-align: center;
      font-weight: 300;
      margin-top: 1%;
      margin-bottom: 1%;
    }
  }

  nav {
    display: flex;
    gap: 7vw;
    justify-content: center;
    align-items: center;
    a {
      font-size: 2vw;
      font-family: "Times New Roman", Times, serif;
      font-style: italic;
      display: inline-block;
      margin-top: 0;
      margin-left: 20px;
      margin-right: 20px;
      &:link {
        color: rgba(0, 0, 0, 0.7);
        text-decoration: none;
        /*get rid of the underline*/
      }

      &:visited {
        color: rgba(153, 102, 153, 0.8);
      }

      &:hover {
        color: rgba(153, 153, 205, 1);
        text-decoration: underline dotted green;
        background-color: white;
      }

      &:active {
        color: rgba(153, 102, 153, 1);
        background-color: white;
        font-style: italic;
      }
    }
  }
}

main {
  aside {
    background: linear-gradient(
      to right,
      rgba(247, 207, 222, 0.4),
      rgba(255, 255, 255, 1)
    );
    border: 5px solid rgba(222, 213, 206, 0.8);
    padding: 20px; /* 可选：增加内边距 */
    margin-left: 10vw; /* 可选：增加外边距 */
    margin-right: 10vw;
    margin-bottom: 10vw;
    margin-top: 5vw;
    box-shadow: 0 0 10px rgba(222, 213, 206, 0.5); /* 添加阴影效果 */

    write {
      font-family: "Great Vibes", cursive;
      font-size: 2vw;
      out {
        color: rgba(255, 102, 102, 1);
      }
      a {
        font-size: 3vh;
        font-style: italic;
        display: inline-block;
        margin-top: 0;
        &:link {
          color: rgba(0, 0, 0, 0.7);
          text-decoration: none;
          /*get rid of the underline*/
        }

        &:visited {
          color: rgba(153, 102, 153, 0.8);
        }

        &:hover {
          color: rgba(153, 153, 205, 1);
          text-decoration: underline dotted green;
          background-color: white;
        }

        &:active {
          color: rgba(153, 102, 153, 1);
          background-color: white;
          font-style: italic;
        }
      }
    }
  }
}
